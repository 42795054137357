import React from 'react';

import { IIconProps } from '@bloom/library/components/Icon/types';

const ChevronIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  rotate = 0,
  width = 20,
}) => (
  <svg className={className} height={width} viewBox="0 0 16 16" width={width}>
    <path
      clipRule="evenodd"
      d="M4.76 6.28c.26-.26.682-.26.942 0l2.195 2.196 2.196-2.196a.667.667 0 1 1 .942.943L8.37 9.89a.667.667 0 0 1-.943 0L4.759 7.223a.667.667 0 0 1 0-.943Z"
      fill={color}
      fillRule="evenodd"
      transform={`rotate(${rotate} 8 8)`}
    />
  </svg>
);
export { ChevronIcon };
