'use client';
import React, { Dispatch, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { doNothing } from '@bloom/ui/utils/empty-value';
import { emptyArray } from '@bloom/ui/utils/empty-value';

import { AsyncStatusEnum } from '@bloom/library/components/hooks/useFetch';
import { getCookie } from '@bloom/library/utils/browser';

export interface IFileUploaderStateItem {
  authToken: string;
  file: File;
  fileId: string;
  progress: number;
  promise: Promise<string>;
  status: AsyncStatusEnum;
  url?: string;
  xhr: XMLHttpRequest;
}

export type FileUploaderStateItem = Record<
  'completed' | 'failed' | 'processing' | 'queue',
  Readonly<Array<IFileUploaderStateItem>>
>;

export type FileUploaderState = Readonly<{
  [destination: string]: FileUploaderStateItem;
}>;

export const defaultUploaderStateItem: FileUploaderStateItem = {
  completed: emptyArray,
  failed: emptyArray,
  processing: emptyArray,
  queue: emptyArray,
};

type FileUploaderAction =
  | {
      destination: string;
      payload: Array<Omit<IFileUploaderStateItem, 'progress'>>;
      type: 'add-files';
    }
  | {
      destination: string;
      payload: Pick<IFileUploaderStateItem, 'fileId' | 'status' | 'url'>;
      type: 'complete-processing';
    }
  | {
      destination: string;
      payload: Pick<IFileUploaderStateItem, 'fileId' | 'status' | 'url'>;
      type: 'failed-processing';
    }
  | {
      destination: string;
      payload: Pick<IFileUploaderStateItem, 'fileId'>;
      type: 'remove-file';
    }
  | {
      destination: string;
      payload: Pick<IFileUploaderStateItem, 'fileId' | 'progress'>;
      type: 'set-progress';
    }
  | { destination: string; type: 'start-processing' }
  | { destination: string; type: 'reset-state' };

function reducer(state: FileUploaderState, action: FileUploaderAction): FileUploaderState {
  const { destination, type } = action;

  switch (type) {
    case 'add-files': {
      const currentState = state[destination] || defaultUploaderStateItem;

      return {
        ...state,
        [destination]: {
          ...currentState,
          queue: [
            ...currentState.queue,
            ...action.payload.map((item) => ({
              ...item,
              progress: 0,
              status: AsyncStatusEnum.IDLE,
            })),
          ],
        },
      };
    }

    case 'complete-processing': {
      const { fileId, status, url } = action.payload;

      let indexToMove = (state[destination]?.processing || []).findIndex(
        (item) => item.fileId === fileId
      );
      let itemToMove = (state[destination]?.processing || [])[indexToMove];

      if (itemToMove) {
        return {
          ...state,
          [destination]: {
            ...state[destination],
            completed: [...(state[destination]?.completed || []), { ...itemToMove, status, url }],
            processing: [
              ...(state[destination]?.processing || []).slice(0, indexToMove),
              ...(state[destination]?.processing || []).slice(indexToMove + 1),
            ],
          },
        };
      }

      indexToMove = (state[destination]?.queue || []).findIndex((item) => item.fileId === fileId);
      itemToMove = (state[destination]?.queue || [])[indexToMove];

      if (itemToMove) {
        return {
          ...state,
          [destination]: {
            ...state[destination],
            completed: [...(state[destination]?.completed || []), { ...itemToMove, status, url }],
            queue: [
              ...(state[destination]?.queue || []).slice(0, indexToMove),
              ...(state[destination]?.queue || []).slice(indexToMove + 1),
            ],
          },
        };
      }

      return state;
    }

    case 'failed-processing': {
      const { fileId, status, url } = action.payload;

      let indexToMove = (state[destination]?.processing || []).findIndex(
        (item) => item.fileId === fileId
      );
      let itemToMove = (state[destination]?.processing || [])[indexToMove];

      if (itemToMove) {
        return {
          ...state,
          [destination]: {
            ...state[destination],
            failed: [...(state[destination]?.failed || []), { ...itemToMove, status, url }],
            processing: [
              ...(state[destination]?.processing || []).slice(0, indexToMove),
              ...(state[destination]?.processing || []).slice(indexToMove + 1),
            ],
          },
        };
      }

      indexToMove = (state[destination]?.queue || []).findIndex((item) => item.fileId === fileId);
      itemToMove = (state[destination]?.queue || [])[indexToMove];

      if (itemToMove) {
        return {
          ...state,
          [destination]: {
            ...state[destination],
            failed: [...(state[destination]?.failed || []), { ...itemToMove, status, url }],
            queue: [
              ...(state[destination]?.queue || []).slice(0, indexToMove),
              ...(state[destination]?.queue || []).slice(indexToMove + 1),
            ],
          },
        };
      }

      return state;
    }

    case 'reset-state': {
      return {
        ...state,
        [destination]: { ...defaultUploaderStateItem },
      };
    }

    case 'remove-file': {
      const { fileId } = action.payload;

      return {
        ...state,
        [destination]: {
          ...state[destination],
          completed: state[destination]?.completed.filter((item) => item.fileId !== fileId) || [],
          failed: state[destination]?.failed.filter((item) => item.fileId !== fileId) || [],
          processing:
            state[destination]?.processing.filter((item) => {
              if (item.fileId === fileId) {
                item.xhr.abort();
                return false;
              }
              return true;
            }) || [],
          queue:
            state[destination]?.queue.filter((item) => {
              if (item.fileId === fileId) {
                item.xhr.abort();
                return false;
              }
              return true;
            }) || [],
        },
      };
    }

    case 'set-progress': {
      const { fileId, progress } = action.payload;
      return {
        ...state,
        [destination]: {
          ...state[destination],
          processing: state[destination]?.processing.map((item) =>
            item.fileId === fileId ? { ...item, progress } : item
          ),
        },
      };
    }

    case 'start-processing': {
      const [itemToMove] = state[destination]?.queue || [];

      if (itemToMove) {
        const activeAccountId = localStorage.getItem('bloom_active_account_id');

        itemToMove.xhr.open('POST', `${process.env.BLOOM_FILE}/upload`);
        if (activeAccountId) {
          itemToMove.xhr.setRequestHeader('x-account', activeAccountId);
        }
        itemToMove.xhr.setRequestHeader('Authorization', `bearer ${itemToMove.authToken}`);
        itemToMove.xhr.setRequestHeader('Accept', 'application/json');

        const formData = new FormData();
        formData.append('file', itemToMove.file);
        itemToMove.xhr.send(formData);

        return {
          ...state,
          [destination]: {
            ...state[destination],
            processing: [
              ...(state[destination]?.processing || []),
              { ...itemToMove, status: AsyncStatusEnum.PENDING },
            ],
            queue: [...(state[destination]?.queue || []).slice(1)],
          },
        };
      }
      return state;
    }
    default:
      return state;
  }
}

interface IProps {
  destination: string;
  onSuccess?: (response: string, originalFile: File) => Promise<unknown>;
}

const FileUploaderContext = React.createContext<[FileUploaderState, Dispatch<FileUploaderAction>]>([
  {},
  doNothing,
]);

export const FileUploaderProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const initialValue: FileUploaderState = useMemo(
    () => ({ general: { ...defaultUploaderStateItem } }),
    []
  );

  const [state, dispatch] = useReducer(reducer, initialValue);

  const value: [FileUploaderState, Dispatch<FileUploaderAction>] = useMemo(
    () => [state, dispatch],
    [state]
  );

  return <FileUploaderContext.Provider value={value}>{children}</FileUploaderContext.Provider>;
};

function useFileUploader(args: IProps) {
  const { destination, onSuccess } = args || {};

  const context = useContext(FileUploaderContext);

  if (!context) {
    throw new Error(`useQuoteRequest must be used within a QuoteRequestProvider`);
  }

  const [state, dispatch] = context;

  useEffect(() => {
    if (
      state[destination] &&
      state[destination].processing.length < 5 &&
      state[destination].queue.length > 0
    ) {
      dispatch({ destination, type: 'start-processing' });
    }
  }, [destination, dispatch, state]);

  const handleFilesSelect = useCallback(
    (files: Array<File> | FileList): void => {
      const arrayOfFiles = Array.from(files);

      dispatch({
        destination,
        payload: arrayOfFiles.map((file) => {
          const fileId = uuidv4();

          const xhr = new XMLHttpRequest();

          xhr.upload.onprogress = (event: ProgressEvent) => {
            const progress = (event.loaded / event.total) * 100;

            dispatch({ destination, payload: { fileId, progress }, type: 'set-progress' });
          };

          const promise = new Promise<string>((resolve, reject) => {
            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  resolve(xhr.responseText);

                  let url = '';
                  try {
                    const response = JSON.parse(xhr.responseText);
                    url = response.file.path;
                  } catch (e) {
                    console.log('🚨 ~ promise ~ xhr.responseText:', e);
                  }

                  const handleComplete = () => {
                    dispatch({
                      destination,
                      payload: { fileId, status: AsyncStatusEnum.SUCCESS, url },
                      type: 'complete-processing',
                    });
                  };

                  if (typeof onSuccess === 'function') {
                    onSuccess(xhr.responseText, file).then(() => {
                      handleComplete();
                    });
                  } else {
                    handleComplete();
                  }
                } else {
                  dispatch({
                    destination,
                    payload: { fileId, status: AsyncStatusEnum.ERROR },
                    type: 'failed-processing',
                  });
                  reject(new Error(xhr.statusText));
                }
              }
            };

            xhr.onabort = () => {
              reject(new Error('File upload aborted by user'));
            };
          });

          const authToken = getCookie('bloom_token');
          const storageToken = sessionStorage.getItem('storage_token');

          return {
            authToken: authToken || storageToken || '',
            file,
            fileId,
            promise,
            status: AsyncStatusEnum.PENDING,
            xhr,
          };
        }),
        type: 'add-files',
      });
    },
    [destination, dispatch, onSuccess]
  );

  const handleFileRemove = useCallback(
    (fileId: string) => {
      dispatch({ destination, payload: { fileId }, type: 'remove-file' });
    },
    [destination, dispatch]
  );

  const handleAbort = useCallback(() => {
    state[destination]?.processing.forEach((item) => {
      item.xhr.abort();
      dispatch({
        destination,
        payload: { fileId: item.fileId, status: AsyncStatusEnum.ABORT },
        type: 'complete-processing',
      });
    });

    state[destination]?.queue.forEach((item) => {
      item.xhr.abort();
      dispatch({
        destination,
        payload: { fileId: item.fileId, status: AsyncStatusEnum.ABORT },
        type: 'complete-processing',
      });
    });
  }, [destination, dispatch, state]);

  const handleReset = useCallback(() => {
    handleAbort();

    dispatch({ destination, type: 'reset-state' });
  }, [destination, dispatch, handleAbort]);

  return useMemo(
    () => ({
      abort: handleAbort,
      removeFile: handleFileRemove,
      reset: handleReset,
      selectFiles: handleFilesSelect,
      ...state[destination],
    }),
    [destination, handleAbort, handleFileRemove, handleFilesSelect, handleReset, state]
  );
}

export { useFileUploader };
